<app-page-header title="Products" [breadcrumbs]="breadcrumbs" [buttons]="buttons"></app-page-header>

<div class="container-fluid">
    <div class="row">
        <div class="col-12">
            <table mat-table [dataSource]="products" class="w-100">
                <ng-container matColumnDef="name">
                    <th mat-header-cell *matHeaderCellDef>Name</th>
                    <td mat-cell *matCellDef="let product">{{ product.name }} - {{product.dimensions}}</td>
                </ng-container>

                <ng-container matColumnDef="status_id">
                    <th mat-header-cell *matHeaderCellDef>Status</th>
                    <td mat-cell *matCellDef="let product">{{ product.status?.title | titlecase }}</td>
                </ng-container>

                <ng-container matColumnDef="direct_employee_cost">
                    <th mat-header-cell *matHeaderCellDef>Direct Employee Cost</th>
                    <td mat-cell *matCellDef="let product">{{ product.direct_employee_cost | currency: 'GBP':'symbol-narrow'}}</td>
                </ng-container>

                <ng-container matColumnDef="sub_contractor_cost">
                    <th mat-header-cell *matHeaderCellDef>Sub Contractor Cost</th>
                    <td mat-cell *matCellDef="let product">{{ product.sub_contractor_cost | currency: 'GBP':'symbol-narrow'}}</td>
                </ng-container>

                <ng-container matColumnDef="material_cost">
                    <th mat-header-cell *matHeaderCellDef>Material Cost</th>
                    <td mat-cell *matCellDef="let product">{{ product.material_cost | currency: 'GBP':'symbol-narrow'}}</td>
                </ng-container>

                <ng-container matColumnDef="sell_price">
                    <th mat-header-cell *matHeaderCellDef>Sell Price</th>
                    <td mat-cell *matCellDef="let product">{{ product.sell_price | currency: 'GBP':'symbol-narrow'}}</td>
                </ng-container>

                <ng-container matColumnDef="manufacturer_id">
                    <th mat-header-cell *matHeaderCellDef>Manufacturer</th>
                    <td mat-cell *matCellDef="let product">{{ product.manufacturer?.name }}</td>
                </ng-container>

                <!-- <ng-container matColumnDef="item_type_id">
                    <th mat-header-cell *matHeaderCellDef>Type</th>
                    <td mat-cell *matCellDef="let product">{{ product.item_type?.title | titlecase }}</td>
                </ng-container> -->

                <!-- <ng-container matColumnDef="width">
                    <th mat-header-cell *matHeaderCellDef>Width (mm)</th>
                    <td mat-cell *matCellDef="let product">{{ product.width }}</td>
                </ng-container>

                <ng-container matColumnDef="height">
                    <th mat-header-cell *matHeaderCellDef>Height (mm)</th>
                    <td mat-cell *matCellDef="let product">{{ product.height }}</td>
                </ng-container> -->

                <ng-container matColumnDef="actions">
                    <th mat-header-cell *matHeaderCellDef>Actions</th>
                    <td mat-cell *matCellDef="let product">
                        <button mat-button (click)="openDialog('edit', product)" *appCan="'edit_products'">Edit</button>
                        <a [swal]="{titleText: 'Are you sure you want to delete this Product?', text: 'You can\'t undo this action.', icon: 'warning'}"
                                (confirm)="onDelete(product.id)"
                                *appCan="'delete_products'"
                                mat-button> Delete </a>
                    </td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
            </table>

            <mat-paginator
                    [length]="totalRows"
                    [pageIndex]="currentPage"
                    [pageSize]="pageSize"
                    [pageSizeOptions]="pageSizeOptions"
                    (page)="pageChanged($event)"
                    aria-label="Select page">
            </mat-paginator>
        </div>
    </div>
</div>
