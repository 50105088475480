<mat-card class="p-4 pt-0 align-self-center">
  <mat-card-header>
      <mat-card-title class="mt-4">Confirm password</mat-card-title>
      <mat-card-subtitle>Choose a new password.</mat-card-subtitle>

      <img src="../../../../assets/images/logo/dwc.png" alt="Fire Survey Pro" class="login-logo" />
  </mat-card-header>

  <form [formGroup]="passwordForm" (ngSubmit)="onSubmit()">
      <mat-card-content>
          <mat-form-field>
              <input type="password" matInput [ngClass]="{ 'is-invalid': submitted && f.password.errors }" placeholder="Password" formControlName="password" autocomplete="new-password">
              <div *ngIf="submitted && f.password.errors" class="invalid-feedback">
                  <div *ngIf="f.password.errors.required">
                      Password is required.
                  </div>
              </div>
          </mat-form-field>

          <mat-form-field>
              <input type="password" matInput [ngClass]="{ 'is-invalid': submitted && f.password.errors }" placeholder="Confirm Password" formControlName="password_confirmation" autocomplete="new-password">
              <div *ngIf="submitted && f.password.errors" class="invalid-feedback">
                  <div *ngIf="f.password.errors.required">
                      Please confirm your password.
                  </div>
              </div>
          </mat-form-field>

          <div class="col-12 mt-3">
              <div *ngIf="error" class="alert alert-danger mt-1">{{error}}</div>
          </div>
      </mat-card-content>

      <mat-card-actions class="m-0">
          <button type="submit" mat-flat-button color="primary">Submit</button>
          <a mat-button color="primary" routerLink="/login">Return to login</a>
      </mat-card-actions>
  </form>
</mat-card>
