import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from '../../services/auth.service';

@Component({
	selector: 'app-email-verified',
	templateUrl: './email-verified.component.html',
	styleUrls: ['./email-verified.component.css']
})
export class EmailVerifiedComponent implements OnInit {

	constructor(
		private activatedRoute: ActivatedRoute,
		private router: Router,
		private authService: AuthService
	) {
		this.activatedRoute.queryParams.subscribe(params => {
			console.log(params);
			if (params.url) {
				this.authService.verifyEmail(decodeURI(params.url)).subscribe(
					response => {
						
						this.router.navigate(['/login']);
					},
					error => {
						this.authService.handleError(error);
					}
				)
			}
		});
	}

	ngOnInit() {
	}

}
