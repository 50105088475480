<div class="page-header">
    <div class="row">
        <ol class="breadcrumb">
            <li
                class="breadcrumb-item"
                [ngClass]="breadcrumb.active ? 'active' : null"
                *ngFor="let breadcrumb of breadcrumbs"
            >
                <a [attr.disabled]="breadcrumb.active" [routerLink]="breadcrumb?.url" [queryParams]="breadcrumb?.queryParams">
                    {{ breadcrumb.title }}
                </a>
            </li>
        </ol>
    </div>

    <div class="row">
        <div class="col-12 col-md-6">
            <h2>{{ title }}</h2>
        </div>

        <div class="col-12 col-md-6">
            <ng-container *ngFor="let button of buttons">
                <ng-container [ngSwitch]="button.type" *appCan="button.permission">
                    <ng-container *ngSwitchCase="'link'" [ngSwitch]="button.material">
                      <a *ngSwitchDefault mat-button class="float-end mx-1" [color]="button.color" [routerLink]="button.url">{{ button.title }}</a>
                      <a *ngSwitchCase="'raised'" mat-raised-button class="float-end mx-1" [color]="button.color" [routerLink]="button.url">{{ button.title }}</a>
                      <a *ngSwitchCase="'stroked'" mat-stroked-button class="float-end mx-1" [color]="button.color" [routerLink]="button.url">{{ button.title }}</a>
                      <a *ngSwitchCase="'flat'" mat-flat-button class="float-end mx-1" [color]="button.color" [routerLink]="button.url">{{ button.title }}</a>
                      <a *ngSwitchCase="'icon'" mat-icon-button class="float-end mx-1" [color]="button.color" [routerLink]="button.url">{{ button.title }}</a>
                      <a *ngSwitchCase="'fab'" mat-fab class="float-end mx-1" [color]="button.color" [routerLink]="button.url">{{ button.title }}</a>
                      <a *ngSwitchCase="'mini-fab'" mat-mini-fab class="float-end mx-1" [color]="button.color" [routerLink]="button.url">{{ button.title }}</a>
                    </ng-container>

                    <ng-container *ngSwitchCase="'button'" [ngSwitch]="button.material">
                      <button *ngSwitchDefault mat-button class="float-end mx-1" [color]="button.color" (click)="callback(button)">{{ button.title }}</button>
                      <button *ngSwitchCase="'raised'" mat-raised-button class="float-end mx-1" [color]="button.color" (click)="callback(button)">{{ button.title }}</button>
                      <button *ngSwitchCase="'stroked'" mat-stroked-button class="float-end mx-1" [color]="button.color" (click)="callback(button)">{{ button.title }}</button>
                      <button *ngSwitchCase="'flat'" mat-flat-button class="float-end mx-1" [color]="button.color" (click)="callback(button)">{{ button.title }}</button>
                      <button *ngSwitchCase="'icon'" mat-icon-button class="float-end mx-1" [color]="button.color" (click)="callback(button)">{{ button.title }}</button>
                      <button *ngSwitchCase="'fab'" mat-fab class="float-end mx-1" [color]="button.color" (click)="callback(button)">{{ button.title }}</button>
                      <button *ngSwitchCase="'mini-fab'" mat-mini-fab class="float-end mx-1" [color]="button.color" (click)="callback(button)">{{ button.title }}</button>
                    </ng-container>
                </ng-container>
            </ng-container>
        </div>
    </div>
</div>
