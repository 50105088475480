import {enableProdMode}         from '@angular/core';
import {platformBrowserDynamic} from '@angular/platform-browser-dynamic';
import * as Sentry              from '@sentry/angular';
import {BrowserTracing}         from '@sentry/tracing';
import {Integration}            from '@sentry/types';
import {environment}            from '@src/environments/environment';
// @ts-ignore
import packageInfo              from '../package.json';
import {AppModule}              from './app/app.module';

Sentry.init({
    dsn: environment.sentryDSN,
    release: `${packageInfo.name}@${packageInfo.version}`,
    environment: environment.environmentName,
    integrations: [
        // @ts-ignore
        new BrowserTracing({
            tracePropagationTargets: [
                'localhost',
                'localhost:4200',
                'vulcan-api.test',
                '192.168.1.46:8100',
                'staging.api.vulcan.hickwood.uk',
                'test.api.vulcan.hickwood.uk',
                'api.firesurveypro.co.uk'
            ],
            // @ts-ignore
            routingInstrumentation: Sentry.routingInstrumentation,
        }) as unknown as Integration,
    ],
    tracesSampleRate: 0.1,
});

if (environment.production) {
    enableProdMode();
}

platformBrowserDynamic()
    .bootstrapModule(AppModule)
    .catch(err => console.log(err));
