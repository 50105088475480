import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@src/environments/environment';
import { Observable } from 'rxjs';

@Injectable({
	providedIn: 'root'
})
export class OrganisationService {

	public baseUrl: string;

	constructor(
		private http: HttpClient
		) {
		this.baseUrl = environment.apiUrl;
	}

	public getOrganisation(): Observable<any>
    {
        return this.http.get(`${this.baseUrl}/tenants`).pipe();
    }
	
	public editOrganisation(tenant_id, data): Observable<any>
    {
        return this.http.post(`${this.baseUrl}/tenants/${tenant_id}?_method=PUT`, data).pipe();
    }

	public createAddress(data): Observable<any>
    {
        return this.http.post(`${this.baseUrl}/tenant-addresses`, data).pipe();
    }

	public editAddress(address_id, data): Observable<any>
    {
        return this.http.put(`${this.baseUrl}/tenant-addresses/${address_id}`, data).pipe();
    }

}
