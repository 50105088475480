import {Injectable}                              from '@angular/core';
import {ActivatedRouteSnapshot, Resolve, Router} from '@angular/router';
import {ApiService}                              from '@src/app/shared/services/api.service';
import {EMPTY, Observable}                       from 'rxjs';
import {catchError}                              from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class OrganisationResolver implements Resolve<any>
{
    public constructor(
        private router: Router,
        private apiService: ApiService
    )
    {
    }

    public resolve(): Observable<any>
    {
        return this.apiService.get(`tenants/1`).pipe(catchError(() => {
            this.router.navigate(['/']).then(r => r);
            return EMPTY;
        }));
    }
}
