import {Injectable} from '@angular/core';
import {environment} from '@src/environments/environment';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {SiteService} from '@src/app/admin/sites/services/site.service';

@Injectable({
    providedIn: 'root'
})
export class BuildingService {
    baseUrl: string;
    buildingId: number;

    constructor(
        private http: HttpClient,
        private siteService: SiteService,
    ) {
        this.baseUrl = environment.apiUrl;
    }

    GetBuildings(): Observable<any> {
        return this.http.get(`${this.baseUrl}/buildings`).pipe();
    }

    CreateBuilding(data): Observable<any> {
        return this.http.post(`${this.baseUrl}/buildings`, data).pipe();
    }

    EditBuilding(data, id): Observable<any> {
        return this.http.put(`${this.baseUrl}/buildings/${id}`, data).pipe();
    }

    DeleteBuilding(id): Observable<any> {
        return this.http.delete(`${this.baseUrl}/buildings/${id}`).pipe();
    }

    GetBuildingData(id) {
        this.siteService.GetSitesData();
        return this.siteService.siteData.find(c => c.id === this.siteService.GetSiteId())
            .buildings.find(c => c.id === this.SetBuildingId(id));
    }

    modifyBuildingData(type, data) {
        const siteIndex = this.siteService.siteData.findIndex(c => c.id === this.siteService.GetSiteId());
        if (type === 'edit') {
            const buildingIndex = this.siteService.siteData.find(c => c.id === this.siteService.GetSiteId())
                .buildings.findIndex(c => c.id === data.id);
            this.siteService.siteData[siteIndex].buildings[buildingIndex].name = data.name;
            this.siteService.siteData[siteIndex].buildings[buildingIndex].location = data.location;
            this.siteService.siteData[siteIndex].buildings[buildingIndex].updated_at = data.updated_at;
        } else {
            this.siteService.siteData[siteIndex].buildings.push(data);
        }

        this.siteService.SetSitesData(this.siteService.siteData);
        return this.siteService.siteData[siteIndex].buildings;
    }

    SetBuildingId(id) {
        localStorage.setItem('buildingId', JSON.stringify(id));
        this.buildingId = id;
        return this.buildingId;
    }

    GetBuildingId() {
        const buildingId = localStorage.getItem('buildingId');

        if (buildingId) {
            try {
                this.buildingId = JSON.parse(buildingId);
            } catch (e) {
                localStorage.removeItem('buildingId');
            }

            return this.buildingId;
        }
    }

    GetBuildingsReport(id): Observable<any> {
        return this.http.get(`${this.baseUrl}/buildings/${id}/report/pins-by-status`).pipe();
    }

    ErrorHandler(response) {
        console.log(response);
    }
}
