import {Injectable} from '@angular/core';
import {environment} from '@src/environments/environment';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class ManufacturerService {
    baseUrl: string;

    constructor(private http: HttpClient) {
        this.baseUrl = environment.apiUrl;
    }

    GetManufacturers(pageNumber: number, pageSize: number = 50, array = null): Observable<any> {
        const url = array
            ? `${this.baseUrl}/manufacturers?array`
            : `${this.baseUrl}/manufacturers?page[number]=${pageNumber}&page[size]=${pageSize}`;

        return this.http.get(url).pipe();
    }

    GetManufacturer(id): Observable<any> {
        return this.http.get(`${this.baseUrl}/manufacturers/${id}`).pipe();
    }

    CreateManufacturer(data): Observable<any> {
        return this.http.post(`${this.baseUrl}/manufacturers`, data).pipe();
    }

    EditManufacturer(data, id): Observable<any> {
        return this.http.put(`${this.baseUrl}/manufacturers/${id}`, data).pipe();
    }

    DeleteManufacturer(id): Observable<any> {
        return this.http.delete(`${this.baseUrl}/manufacturers/${id}`).pipe();
    }

    ErrorHandler(response) {
        console.log(response);
    }
}
