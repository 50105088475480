import {NgModule}             from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {AuthGuard}            from '@src/app/core/guards/auth.guard';
import {NoAuthGuard}          from '@src/app/core/guards/no-auth.guard';
import {PermissionGuard}      from '@src/app/core/guards/permission.guard';
import {AdminComponent}       from '@src/app/layout/admin/admin.component';
import {NotFoundComponent}    from '@src/app/shared/components/error/error.component';
import { OrganisationResolver } from './admin/settings/resolvers/organisation.resolver';
import { TenantGuard } from './core/guards/tenant.guard';
import { OrganisationInitialLoginComponent } from './interim/organisation-initial-login/organisation-initial-login.component';

export const routes: Routes = [
    {
        path: '',
        pathMatch: 'full',
        redirectTo: 'app',
    },
    {
        path: '',
        canActivate: [NoAuthGuard],
        canActivateChild: [NoAuthGuard],
        loadChildren: () => import('src/app/core/core.module').then(m => m.CoreModule)
    },
    {
        path: 'signed-in-redirect',
        pathMatch: 'full',
        redirectTo: 'app/sites'
    },
    {
        path: 'app',
        canActivate: [AuthGuard],
        canActivateChild: [PermissionGuard],
        component: AdminComponent,
        data: {breadcrumb: 'Home'},
        loadChildren: () => import('@src/app/layout/admin/admin.module').then(m => m.AdminModule)
    },
    {
        path: 'organisation-settings',
        canActivate: [AuthGuard],
        resolve: {
            organisation: OrganisationResolver
        },
        component: OrganisationInitialLoginComponent
    },
    {path: '**', component: NotFoundComponent}
];


@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule]
})
export class AppRoutingModule
{
}
