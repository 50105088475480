import {Injectable}  from '@angular/core';
import {BaseService} from '@src/app/shared/services/base.service';

;
import {environment} from '@src/environments/environment';

@Injectable({
    providedIn: 'root'
})
export class StorageService extends BaseService
{
    public prefix = environment.storagePrefix;

    public constructor()
    {
        super();
    }

    public write(key: string, data: any)
    {
        localStorage.setItem(`${this.prefix}_${key}`, data);
    }

    public read(key: string)
    {
        // try {
        //     this.mapZoomLevel = JSON.parse(mapZoomLevel);
        // } catch (e) {
        //     localStorage.removeItem('mapZoomLevel');
        //     this.mapZoomLevel = null;
        // }

        return localStorage.getItem(`${this.prefix}_${key}`) ?? null;
    }

    public remove(key: string)
    {
        localStorage.removeItem(`${this.prefix}_${key}`)
    }
}
