<mat-card class="p-4 pt-0 align-self-center">
    <mat-card-header>
        <mat-card-title class="mt-4">Forgot password</mat-card-title>
        <mat-card-subtitle>Request a reset password link.</mat-card-subtitle>

        <img src="../../../../assets/images/logo/dwc.png" alt="Fire Survey Pro" class="login-logo" />
    </mat-card-header>

    <form [formGroup]="forgotPasswordForm" (ngSubmit)="onSubmit()">
        <mat-card-content>
            <mat-form-field>
                <input type="text" [ngClass]="{ 'is-invalid': submitted && f.email.errors }" matInput placeholder="Email" formControlName="email" autocomplete="email">
                <div *ngIf="submitted && f.email.errors" class="invalid-feedback">
                    <div *ngIf="f.email.errors.required">
                        Email is required.
                    </div>

                    <div *ngIf="f.email.errors.email">
                        Please enter a valid email address.
                    </div>
                </div>
            </mat-form-field>

            <div class="col-12 mt-3">
                <div *ngIf="error" class="alert alert-danger mt-1">{{error}}</div>
            </div>
        </mat-card-content>

        <mat-card-actions class="m-0">
            <button type="submit" mat-flat-button color="primary">Submit</button>
            <a mat-button color="primary" routerLink="/login">Return to login</a>
        </mat-card-actions>
    </form>
</mat-card>
