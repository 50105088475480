import {HttpClient}     from '@angular/common/http';
import {Injectable}     from '@angular/core';
import {Title}          from '@angular/platform-browser';
import {Building}       from '@src/app/admin/buildings/models/building.model';
import {Drawing}        from '@src/app/admin/drawings/models/drawing.model';
import {Floor}          from '@src/app/admin/floors/models/floor.model';
import {Pin}            from '@src/app/admin/pins/models/pin.model';
import {Site}           from '@src/app/admin/sites/models/site.model';
import {StorageService} from '@src/app/core/services/storage.service';
import {BaseService}    from '@src/app/shared/services/base.service';
import {Observable}     from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class GlobalService extends BaseService
{
    public constructor(
        private http: HttpClient,
        private storageService: StorageService,
        private titleService: Title,
    )
    {
        super();
    }

    public set pageTitle(title: string)
    {
        this.titleService.setTitle(title || 'Dashboard');
    }

    public get pageTitle(): string
    {
        return this.titleService.getTitle();
    }

    public set site(site: Site)
    {
        this.storageService.write('site', JSON.stringify(site));
    }

    public get site(): Site
    {
        const data = this.storageService.read('site');
        return data ? JSON.parse(data) : null;
    }

    public set building(building: Building)
    {
        this.storageService.write('building', JSON.stringify(building));
    }

    public get building(): Building
    {
        const data = this.storageService.read('building');
        return data ? JSON.parse(data) : null;
    }

    public set floor(floor: Floor)
    {
        this.storageService.write('floor', JSON.stringify(floor));
    }

    public get floor(): Floor
    {
        const data = this.storageService.read('floor');
        return data ? JSON.parse(data) : null;
    }

    public set drawing(drawing: Drawing)
    {
        this.storageService.write('drawing', JSON.stringify(drawing));
    }

    public get drawing(): Drawing
    {
        const data = this.storageService.read('drawing');
        return data ? JSON.parse(data) : null;
    }

    public set pin(pin: Pin)
    {
        this.storageService.write('pin', JSON.stringify(pin));
    }

    public get pin(): Pin
    {
        const data = this.storageService.read('pin');
        return data ? JSON.parse(data) : null;
    }

    public set defects(value: any)
    {
        this.storageService.write('defects', JSON.stringify(value));
    }

    public get defects()
    {
        const data = this.storageService.read('defects');
        return data ? JSON.parse(data) : null;
    }

    public set products(products: any)
    {
        this.storageService.write('products', JSON.stringify(products));
    }

    public get products()
    {
        const data = this.storageService.read('products');
        return data ? JSON.parse(data) : null;
    }

    public set mapZoomLevel(value: any)
    {
        this.storageService.write('mapZoomLevel', JSON.stringify(value));
    }

    public get mapZoomLevel()
    {
        const data = this.storageService.read('mapZoomLevel');
        return data ? JSON.parse(data) : null;
    }

    public set mapCenter(value: any)
    {
        this.storageService.write('mapCenter', JSON.stringify(value));
    }

    public get mapCenter()
    {
        const data = this.storageService.read('mapmapCenterZoomLevel');
        return data ? JSON.parse(data) : null;
    }

    public getStatuses(model, directory = null): Observable<any>
    {
        const url = directory ? `${this.apiUrl}/models/${model}/${directory}` : `${this.apiUrl}/models/${model}`;
        return this.http.get(url).pipe();
    }
}
