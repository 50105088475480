import {NgModule}                from '@angular/core';
import {ForgotPasswordComponent} from '@src/app/core/components/forgot-password/forgot-password.component';
import {LoginComponent}          from '@src/app/core/components/login/login.component';
import {ResetPasswordComponent}  from '@src/app/core/components/reset-password/reset-password.component';
import {SharedModule}            from '@src/app/shared/shared.module';
import { CreatePasswordComponent } from './components/create-password/create-password.component';
import { EmailVerifiedComponent } from './components/email-verified/email-verified.component';
import { SignUpComponent } from './components/sign-up/sign-up.component';
import {CoreRoutingModule}       from './core-routing.module';

@NgModule({
    imports: [
        CoreRoutingModule,
        SharedModule,
    ],
    declarations: [
        LoginComponent,
        ForgotPasswordComponent,
        ResetPasswordComponent,
        SignUpComponent,
        EmailVerifiedComponent,
        CreatePasswordComponent
    ],
    exports: [
        LoginComponent,
        SharedModule
    ]
})
export class CoreModule
{
}
