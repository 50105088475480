import {Injectable} from '@angular/core';
import {environment} from '@src/environments/environment';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {SiteService} from '@src/app/admin/sites/services/site.service';
import {BuildingService} from '@src/app/admin/buildings/services/building.service';

@Injectable({
    providedIn: 'root'
})
export class FloorService {
    baseUrl: string;
    floorId: number;

    constructor(
        private http: HttpClient,
        private siteService: SiteService,
        private buildingService: BuildingService,
    ) {
        this.baseUrl = environment.apiUrl;
    }

    CreateFloor(data): Observable<any> {
        return this.http.post(`${this.baseUrl}/floors`, data).pipe();
    }

    EditFloor(data, id): Observable<any> {
        return this.http.put(`${this.baseUrl}/floors/${id}`, data).pipe();
    }

    DeleteFloor(id): Observable<any> {
        return this.http.delete(`${this.baseUrl}/floors/${id}`).pipe();
    }

    GetFloorData(id) {
        this.siteService.GetSitesData();
        return this.siteService.siteData.find(c => c.id === this.siteService.GetSiteId())
            .buildings.find(c => c.id === this.buildingService.GetBuildingId())
            .floors.find(c => c.id === this.SetFloorId(id));
    }

    modifyFloorData(type, data) {
        const siteIndex = this.siteService.siteData.findIndex(c => c.id === this.siteService.GetSiteId());
        const buildingIndex = this.siteService.siteData.find(c => c.id === this.siteService.GetSiteId())
            .buildings.findIndex(c => c.id === this.buildingService.GetBuildingId());

        if (type === 'edit') {
            const floorIndex = this.siteService.siteData.find(c => c.id === this.siteService.GetSiteId())
                .buildings.find(c => c.id === this.buildingService.GetBuildingId()).floors.findIndex(c => c.id === data.id);
            this.siteService.siteData[siteIndex].buildings[buildingIndex].floors[floorIndex].name = data.name;
            this.siteService.siteData[siteIndex].buildings[buildingIndex].floors[floorIndex].updated_at = data.updated_at;
        } else {
            this.siteService.siteData[siteIndex].buildings[buildingIndex].floors.push(data);
        }

        this.siteService.SetSitesData(this.siteService.siteData);
        return this.siteService.siteData[siteIndex].buildings[buildingIndex].floors;
    }

    SetFloorId(id) {
        localStorage.setItem('floorId', JSON.stringify(id));
        this.floorId = id;
        return this.floorId;
    }

    GetFloorId() {
        const floorId = localStorage.getItem('floorId');

        if (floorId) {
            try {
                this.floorId = JSON.parse(floorId);
            } catch (e) {
                localStorage.removeItem('floorId');
            }

            return this.floorId;
        }
    }

    GetFloorsReport(id): Observable<any> {
        return this.http.get(`${this.baseUrl}/floors/${id}/report/pins-by-status`).pipe();
    }

    ErrorHandler(response) {
        console.log(response);
    }
}
