import {Component, Inject, OnInit}                       from '@angular/core';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {MAT_DIALOG_DATA, MatDialogRef}                   from '@angular/material/dialog';
import {Manufacturer}                                    from '@src/app/admin/manufacturers/models/manufacturer.model';
import {ManufacturerService}                             from '@src/app/admin/manufacturers/services/manufacturer.service';
import {PinHistoryService}                               from '@src/app/admin/pin-histories/services/pin-history.service';
import {ItemType}                                        from '@src/app/admin/products/models/item-type.model';
import {ProductService}                                  from '@src/app/admin/products/services/product.service';
import {Status}                                          from '@src/app/core/models/status.model';
import {GlobalService}                                   from '@src/app/core/services/global.service';
import {ToastrService}                                   from 'ngx-toastr';
import {Product}                                         from '../../models/product.model';

@Component({
    selector: 'app-create',
    templateUrl: './products-form.component.html',
    styleUrls: ['./products-form.component.scss']
})
export class ProductsFormComponent implements OnInit
{
    public title = 'Create Product';
    public product: Product;
    public products: Product[];
    public productStatuses: Status[];
    public itemTypes: ItemType[];
    public manufacturers: Manufacturer[];
    public newProd: boolean = false;
    public productForm = new FormGroup({
        name: new FormControl(''),
        status_id: new FormControl(''),
        manufacturer_id: new FormControl(''),
        // item_type_id: new FormControl(''),
        direct_employee_cost: new FormControl(''),
        sub_contractor_cost: new FormControl(''),
        material_cost: new FormControl(''),
        sell_price: new FormControl(''),
        diameter_check: new FormControl(false),
        width: new FormControl(''),
        height: new FormControl(''),
        diameter: new FormControl(''),
    });
    public productReplacementForm = new FormGroup({
        product_id: new FormControl(''),
        quantity: new FormControl(''),
    });
    public newProductForm = new FormGroup({
        quantity: new FormControl(''),
    });

    public constructor(
        @Inject(MAT_DIALOG_DATA) public data,
        private globalService: GlobalService,
        private productService: ProductService,
        private manufacturerService: ManufacturerService,
        private formBuilder: FormBuilder,
        public ProductsForm: MatDialogRef<ProductsFormComponent>,
        private toast: ToastrService,
        private pinHistoryService: PinHistoryService
    )
    {
    }

    public ngOnInit(): void
    {
        // this.title = this.data.action === 'edit' || this.data.action === 'replace' ?  : this.title;
        if (this.data.action === 'edit') {
            this.title = 'Edit Product';
        } else if (this.data.action === 'replace') {
            this.title = 'Replace Product';
            this.newProd = false;

            this.productService.GetProductsByManufacturer().subscribe(response => {
                this.products = response.data.items;
            }, response => this.globalService.handleError(response));

            this.productReplacementForm = this.formBuilder.group({
                'product_id': new FormControl('', Validators.compose([
                    Validators.required
                ])),
                'quantity': new FormControl(this.data.itemProduct.count ?? 1, Validators.compose([
                    Validators.required
                ]))
            });

            this.newProductForm = this.formBuilder.group({
                'quantity': new FormControl(this.data.itemProduct.count ?? 1, Validators.compose([
                    Validators.required
                ]))
            });
        }

        this.globalService.getStatuses('ProductStatus', 'Products').subscribe(
            response => this.productStatuses = response.data.items,
            response => this.globalService.handleError(response)
        );

        // this.globalService.getStatuses('ItemType', 'ItemTypes').subscribe(
        //     response => this.itemTypes = response.data.items,
        //     response => this.globalService.handleError(response)
        // );

        this.manufacturerService.GetManufacturers(0, 0, true).subscribe(
            response => this.manufacturers = response,
            response => this.manufacturerService.ErrorHandler(response)
        );

        this.product = this.data.product ?? null;
        this.productForm = this.formBuilder.group({
            'name': new FormControl(this.product?.name || '', Validators.compose([
                Validators.required
            ])),
            'status_id': new FormControl(this.product?.status_id ?? 1, Validators.compose([
                Validators.required
            ])),
            'manufacturer_id': new FormControl(this.product?.manufacturer?.id, Validators.compose([
                Validators.required
            ])),
            // 'item_type_id': new FormControl(this.product?.item_type_id, Validators.compose([
            //     Validators.required
            // ])),
            'direct_employee_cost': new FormControl(this.product?.direct_employee_cost, Validators.compose([
                Validators.required
            ])),
            'sub_contractor_cost': new FormControl(this.product?.sub_contractor_cost, Validators.compose([
                Validators.required
            ])),
            'material_cost': new FormControl(this.product?.material_cost, Validators.compose([
                Validators.required
            ])),
            'sell_price': new FormControl(this.product?.sell_price, Validators.compose([
                Validators.required
            ])),
            'width': new FormControl(this.product?.width, Validators.compose([
                Validators.required
            ])),
            'height': new FormControl(this.product?.height, Validators.compose([
                Validators.required
            ])),
            'diameter_check': new FormControl(!!this.product?.diameter, Validators.compose([])),
            'diameter': new FormControl(this.product?.diameter, Validators.compose([
                Validators.required
            ])),
        });

        this.productForm.controls['diameter_check'].valueChanges
            .subscribe(value => {
                    this.modifyDimensionsValidation(value);
                }
            );

        this.modifyDimensionsValidation(this.productForm.controls['diameter_check'].value);
    }

    public submitForm()
    {
        if (this.data.action === 'edit') {
            if (this.productForm.valid) {
                this.productService.EditProduct(this.productForm.value, this.product.id).subscribe(
                    response => {
                        let message = 'Product successfully edited';
                        this.toast.success(message);
                        this.closeDialog(response);
                    },
                    response => this.productService.ErrorHandler(response)
                );
            }
        } else if (this.data.action === 'replace') {
            if (this.productForm.valid && this.newProductForm.valid) {
                this.productService.CreateProduct(this.productForm.value).subscribe(
                    response => {

                        var data = {
                            product_id: response.data.product.id,
                            original_product_id: this.data.itemProduct.id,
                            quantity: this.newProductForm.get('quantity').value
                        };

                        this.pinHistoryService.SwapProductInPinHistory(this.data.pinHistoryId, data).subscribe(historyResponse => {
                            let message = 'Product successfully swapped.';
                            this.toast.success(message);
                            this.closeDialog(historyResponse);
                        }, historyResponse => {
                            this.toast.error(historyResponse.message, 'Server Error');
                            this.globalService.handleError(historyResponse);
                        });
                    },
                    response => this.productService.ErrorHandler(response)
                );
            } else if (this.productReplacementForm.valid) {
                var data = {
                    product_id: this.productReplacementForm.get('product_id').value,
                    quantity: this.productReplacementForm.get('quantity').value,
                    original_product_id: this.data.itemProduct.id
                };

                this.pinHistoryService.SwapProductInPinHistory(this.data.pinHistoryId, data).subscribe(historyResponse => {
                    let message = 'Product successfully swapped.';
                    this.toast.success(message);
                    this.closeDialog(historyResponse);
                }, historyResponse => {
                    this.toast.error(historyResponse.message, 'Server Error');
                    this.globalService.handleError(historyResponse);
                });
            } else {
                this.toast.error('Please check the form is filled out correctly and try again!', 'Validation Error');
            }
        } else {
            if (this.productForm.valid) {
                this.productService.CreateProduct(this.productForm.value).subscribe(
                    response => {
                        let message = 'Product successfully saved.';
                        this.toast.success(message);
                        this.closeDialog(response);
                    },
                    response => this.productService.ErrorHandler(response)
                );
            }
        }


    }

    public closeDialog(response = null): void
    {
        this.ProductsForm.close(response);
    }

    public modifyDimensionsValidation(value)
    {
        if (value) {
            this.productForm.get('diameter').setValidators(Validators.required);
            this.productForm.get('width').setValue(null);
            this.productForm.get('height').setValue(null);
            this.productForm.get('width').clearValidators();
            this.productForm.get('height').clearValidators();
        } else {
            this.productForm.get('diameter').setValue(null);
            this.productForm.get('diameter').clearValidators();
            this.productForm.get('width').setValidators(Validators.required);
            this.productForm.get('height').setValidators(Validators.required);
        }

        this.productForm.get('diameter').updateValueAndValidity();
        this.productForm.get('width').updateValueAndValidity();
        this.productForm.get('height').updateValueAndValidity();
    }
}
