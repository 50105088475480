import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { environment } from '@src/environments/environment';
import { AuthService } from '../../services/auth.service';
import { CustomValidators } from '../../validators/check-password';

@Component({
	selector: 'app-sign-up',
	templateUrl: './sign-up.component.html',
	styleUrls: ['./sign-up.component.scss']
})
export class SignUpComponent implements OnInit {
	public signupForm: FormGroup;
	public error: any;
	public submitted = false;

	constructor(
		private router: Router,
		private fb: FormBuilder,
		private authService: AuthService,
		private snackBar: MatSnackBar,
	) {

	}

	ngOnInit() {
		this.signupForm = this.fb.group({
			company_name: [null, [Validators.required]],
			name: [null, [Validators.required]],
			email: [null, [Validators.required, Validators.email]],
			password: [null, [Validators.required]],
			password_confirmation: [null, [Validators.required]],
			frontend_url: [`${environment.localPath}/email-verified`]
			// mobile: [null, []],
			// telephone: [null, []],
		}, {
			Validators: CustomValidators.MatchingPasswords
		});
	}

	get f() {
		return this.signupForm.controls;
	}

	public onSubmit(): void {
		console.log(this.signupForm)
		this.submitted = true;

		if (this.signupForm.invalid) {
			return;
		}


		if (this.f.password.value === this.f.password_confirmation.value) {
			this.snackBar.open("Form Submitted", 'OK', { duration: 2500 });
			this.signupForm.disable();

			this.authService.register(this.signupForm.value).subscribe(
				() => {
					this.snackBar.open('Signed Up succesfully.', 'Ok');
					this.router.navigateByUrl('login');
				},
				(response: HttpErrorResponse) => {
					this.signupForm.enable();
					this.snackBar.open(response?.error?.message, 'Ok');
				}
			);
		} else {
			this.snackBar.open("Passwords don't match", 'OK', { duration: 2500 });
		}

		
	}

	ConfirmedValidator(controlName: string, matchingControlName: string) {
		return (formGroup: FormGroup) => {
			const control = formGroup.controls[controlName];
			const matchingControl = formGroup.controls[matchingControlName];
			if (
				matchingControl.errors &&
				!matchingControl.errors.confirmedValidator
			) {
				return;
			}
			if (control.value !== matchingControl.value) {
				matchingControl.setErrors({ confirmedValidator: true });
			} else {
				matchingControl.setErrors(null);
			}
		};
	}
}