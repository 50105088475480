import {Directive, Input, TemplateRef, ViewContainerRef} from '@angular/core';
import {PermissionsService} from '@src/app/core/services/permissions.service';

@Directive({
    selector: '[appHasRole]'
})
export class HasRoleDirective {
    constructor(
        private templateRef: TemplateRef<any>,
        private viewContainerRef: ViewContainerRef,
        private permissionsService: PermissionsService,
    ) {
    }

    @Input()
    set appHasRole(role: string) {
        if (this.permissionsService.hasRole(role)) {
            this.viewContainerRef.createEmbeddedView(this.templateRef);
        } else {
            this.viewContainerRef.clear();
        }
    }
}
