import {Directive, Input, TemplateRef, ViewContainerRef} from '@angular/core';
import {PermissionsService} from '@src/app/core/services/permissions.service';

@Directive({
    selector: '[appCant]'
})
export class CantDirective {
    constructor(
        private templateRef: TemplateRef<any>,
        private viewContainerRef: ViewContainerRef,
        private permissionsService: PermissionsService,
    ) {
    }

    @Input()
    set appCant(permission: string) {
        if (this.permissionsService.hasRole('super-admin') || this.permissionsService.cant(permission)) {
            this.viewContainerRef.createEmbeddedView(this.templateRef);
        } else {
            this.viewContainerRef.clear();
        }
    }
}
