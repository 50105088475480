import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {ActivatedRoute, Router} from '@angular/router';
import {AuthService} from '../../services/auth.service';
import {ToastrService} from 'ngx-toastr';
import {HttpErrorResponse} from '@angular/common/http';
import {MatSnackBar} from '@angular/material/snack-bar';

@Component({
    selector: 'app-forgot-password',
    templateUrl: './forgot-password.component.html',
    styleUrls: ['./forgot-password.component.scss']
})
export class ForgotPasswordComponent implements OnInit {
    public forgotPasswordForm: FormGroup;
    public error: any;
    public submitted = false;

    constructor(
        private activatedRoute: ActivatedRoute,
        private router: Router,
        private fb: FormBuilder,
        private authService: AuthService,
        private snackBar: MatSnackBar,
    ) {
        if (this.authService.authenticated) {
            this.router.navigate(['/app']).then(r => r);
        }
    }

    get f() {
        return this.forgotPasswordForm.controls;
    }

    public ngOnInit(): void {
        this.forgotPasswordForm = this.fb.group({
            email: [null, [Validators.required, Validators.email]],
        });
    }

    public onSubmit(): void {
        this.submitted = true;

        if (this.forgotPasswordForm.invalid) {
            return;
        }

        this.forgotPasswordForm.disable();

        this.authService.forgotPassword(this.forgotPasswordForm.value).subscribe(
            () => {
                this.snackBar.open('Password reset requested.', 'Ok');
                this.router.navigate(['/login']);
            },
            (response: HttpErrorResponse) => {
                this.forgotPasswordForm.enable();
                this.snackBar.open(response?.error?.message, 'Ok');
            }
        );
    }
}
