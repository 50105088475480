import {HttpClient}  from '@angular/common/http';
import {Injectable}  from '@angular/core';
import {Site}        from '@src/app/admin/sites/models/site.model';
import {environment} from '@src/environments/environment';
import {Observable}  from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class SiteService
{
    public baseUrl: string;
    public site: Site;
    public siteData: Site[]; // TODO: Refactor out
    public siteId: number; // TODO: Refactor out

    public constructor(private http: HttpClient)
    {
        this.baseUrl = environment.apiUrl;
    }

    public EditSite(id, data): Observable<any>
    {
        return this.http.put(`${this.baseUrl}/sites/${id}`, data).pipe();
    }

    public DeleteSite(id): Observable<any>
    {
        return this.http.delete(`${this.baseUrl}/sites/${id}`).pipe();
    }

    public SetSitesData(value)
    {
        localStorage.setItem('siteData', JSON.stringify(value));
        this.siteData = value;
    }

    public getSitesReport(id, data): Observable<any>
    {
        return this.http.post(`${this.baseUrl}/sites/${id}/report`, data);
    }

    public getPinHistoriesReport(id): Observable<Blob>
    {
        return this.http.get(`${this.baseUrl}/sites/${id}/report/pins-histories`, {responseType: 'blob'});
    }

    public GetSitesData()
    {
        const siteData = localStorage.getItem('siteData');

        if (siteData) {
            try {
                this.siteData = JSON.parse(siteData);
            } catch (e) {
                localStorage.removeItem('siteData');
            }

            return this.siteData;
        }
    }

    public GetSiteId()
    {
        const siteId = localStorage.getItem('siteId');

        if (siteId) {
            try {
                this.siteId = JSON.parse(siteId);
            } catch (e) {
                localStorage.removeItem('siteId');
            }

            return +this.siteId;
        }
    }

    public CreateJob(data): Observable<any>
    {
        return this.http.post(`${this.baseUrl}/jobs/create`, data).pipe();
    }

    public GetSitesReport(id): Observable<any>
    {
        return this.http.get(`${this.baseUrl}/sites/${id}/report/pins-by-status`).pipe();
    }

    public ErrorHandler(response)
    {
        console.error(response);
    }
}
