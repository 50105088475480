import {Component}                                                  from '@angular/core';
import {FormArray, FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {MatSnackBar}                                                from '@angular/material/snack-bar';
import {ActivatedRoute, Router}                                     from '@angular/router';
import {AddressType}                                                from '@src/app/admin/settings/models/addresses.model';
import {OrganisationService}                                        from '@src/app/admin/settings/services/organisation.service';
import {User}                                                       from '@src/app/admin/users/models/user.model';
import {UsersService}                                               from '@src/app/admin/users/services/users.service';
import {AuthService}                                                from '@src/app/core/services/auth.service';
import {ApiService}                                                 from '@src/app/shared/services/api.service';
import {environment}                                                from '@src/environments/environment';

@Component({
    selector: 'app-organisation-initial-login',
    templateUrl: './organisation-initial-login.component.html',
    styleUrls: ['./organisation-initial-login.component.scss']
})
export class OrganisationInitialLoginComponent
{
    public title = '';
    public organisationName = '';
    public organisationForm;
    public submitted: boolean = false;
    public systemUsers: User;
    public imagePreview: any = null;

    public get f()
    {
        return this.organisationForm.controls;
    }

    public get system_owners()
    {
        return this.f['system_owners'] as FormArray;
    }

    public constructor(
        private authService: AuthService,
        public router: Router,
        public userService: UsersService,
        private fb: FormBuilder,
        private organisationService: OrganisationService,
        private snackBar: MatSnackBar,
        private apiService: ApiService,
        private activatedRoute: ActivatedRoute
    )
    {
        this.title = environment.title;

        this.activatedRoute.data.subscribe(
            response => {
                let responseData = response.organisation.data;

                this.organisationName = responseData.name ?? '';

                this.organisationForm = new FormGroup({
                    trading_address: new FormGroup({
                        address_id: new FormControl(responseData.trading_address?.id ?? null),
                        type_id: new FormControl(AddressType.TradingAddress ?? null),
                        title: new FormControl('Trading Address'),
                        address_line_1: new FormControl(responseData.trading_address?.address_line_1 ?? null, Validators.required),
                        address_line_2: new FormControl(responseData.trading_address?.address_line_2 ?? null),
                        address_line_3: new FormControl(responseData.trading_address?.address_line_3 ?? null),
                        locality: new FormControl(responseData.trading_address?.locality ?? null),
                        county: new FormControl(responseData.trading_address?.county ?? null),
                        country: new FormControl(responseData.trading_address?.country ?? null),
                        postcode: new FormControl(responseData.trading_address?.postcode ?? null, Validators.required),
                    }),
                    billing_address: new FormGroup({
                        address_id: new FormControl(responseData.billing_address?.id ?? null),
                        type_id: new FormControl(AddressType.BillingAddress ?? null),
                        title: new FormControl('Billing Address'),
                        address_line_1: new FormControl(responseData.billing_address?.address_line_1 ?? null, Validators.required),
                        address_line_2: new FormControl(responseData.billing_address?.address_line_2 ?? null),
                        address_line_3: new FormControl(responseData.billing_address?.address_line_3 ?? null),
                        locality: new FormControl(responseData.billing_address?.locality ?? null),
                        county: new FormControl(responseData.billing_address?.county ?? null),
                        country: new FormControl(responseData.billing_address?.country ?? null),
                        postcode: new FormControl(responseData.billing_address?.postcode ?? null, Validators.required),
                    }),
                });
            },
            error => {
                this.apiService.handleError(error);
            }
        );
    }

    public async submitForm()
    {
        if (this.f.billing_address.valid && this.f.trading_address.valid) {
            let data = await new Promise<boolean>(async (resolve, reject) => {
                let created = true;

				await this.organisationService.createAddress(this.f.billing_address.value).toPromise().then(
					response => {
						let tenant = this.authService.tenant;
						tenant.billing_address = response.data;
						this.authService.tenant = tenant;
					}).catch(
					error => {
						created = false;
						reject(false);
					});

				await this.organisationService.createAddress(this.f.trading_address.value).toPromise().then(
					response => {
						let tenant = this.authService.tenant;
						tenant.trading_address = response.data;
						this.authService.tenant = tenant;
					}).catch(
					error => {
						created = false;
						reject(false);
					});

                if (created) {
                    this.router.navigateByUrl('/app/sites');
                } else {
                    this.snackBar.open('Please fill out the form correctly', 'Ok', {duration: 2500});
                }
                resolve(true);
            });
        }
    }

    public onFileChange(pFileList: File[])
    {
        this.f.logo.setValue(pFileList[0]);
        let reader = new FileReader();
        reader.onload = (event: any) => {
            this.imagePreview = event.target.result;
        };
        reader.readAsDataURL(pFileList[0]);
    }

    public removeFile()
    {
        this.f.logo.setValue(null);
        this.imagePreview = null;
    }

    public sameAsTradingAddress(event)
    {
        if (event.checked) {
            this.f.billing_address.setValue(this.f.trading_address.value);
            this.f.billing_address.controls['type_id'].setValue(AddressType.BillingAddress);
            this.f.billing_address.controls['title'].setValue('Billing Address');
            console.log(this.f);
        }
    }

    public addSystemOwner(owner_id?)
    {
        const system_owner = this.fb.group({
            id: [owner_id ?? null, Validators.required],
            name: ['beginner', Validators.required]
        });

        this.system_owners.push(system_owner);
    }

    public removeSystemOwner(ownerIndex: number)
    {
        this.system_owners.removeAt(ownerIndex);
    }

    public forceUppercaseConditionally(formGroup, formControlName, event)
    {
        this.organisationForm.get(`${formGroup}.${formControlName}`).setValue(event.target.value.toUpperCase());
    }

    public logout()
    {
        this.authService.logout();
        this.router.navigate(['/login']).then(r => r);
    }
}
