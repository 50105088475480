import {HttpErrorResponse, HttpEvent, HttpHandler, HttpHeaders, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable, throwError} from 'rxjs';
import {catchError} from 'rxjs/operators';
import {AuthService} from '../services/auth.service';

@Injectable()
export class TokenInterceptor implements HttpInterceptor {
    constructor(
        private authService: AuthService,
    ) {
    }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        let newRequest = request.clone();

        if (this.authService.accessToken && this.authService.tenant?.id) {
            newRequest = request.clone({
               headers: new HttpHeaders({
                'Authorization': `Bearer ${this.authService.accessToken}`,
                'X-Tenant': `${this.authService.tenant.id}`
               })
            });
        }

        return next.handle(newRequest).pipe(
            catchError((error: any) => {
                if (error instanceof HttpErrorResponse && error.status === 401) {
                    // this.authService.logout();
                    // location.reload();
                }
                return throwError(error);
            })
        );
    }
}
